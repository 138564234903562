<template>
  <div>
    <div class="all">
      <!-- 导航 -->
      <van-nav-bar title="申请售后" left-arrow @click-left="onClickLeft" />
      <!-- 商品 -->
      <div class="item">
        <div class="_item">
          <p class="title">售后商品</p>
          <div class="item-info">
            <img
              style="width: 80px; height: 80px"
              :src="refundInfo[0].picUrl"
              alt
            />
            <div class="desc-spec">
              <div class="desc">
                <span
                  style="display: inline-block; width: 180px; color: #333333"
                  >{{ refundInfo[0].spuName.substr(0, 20) + "..." }}</span
                >
                <span>￥{{ refundInfo[0].paymentPrice }}</span>
              </div>
              <div class="spec">
                <span>规格：{{ refundInfo[0].specInfo == 'null' || refundInfo[0].specInfo == null ? '统一规格' : refundInfo[0].specInfo }}</span>
                <span>*{{ refundInfo[0].quantity }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="choose-serve">选择服务类型</p>
      <!-- 选择服务 -->
      <div class="choose-item">
        <div class="only-return" @click="backReason">
          <span class="only">仅退款</span>
          <span v-if="chooseReason" style="color: #333333">{{
            chooseReason
          }}</span>
          <span v-else>2022-08-09前可申请</span>
          <van-icon name="arrow" size="18" />
        </div>
        <div
          class="only-return"
          style="border-bottom: none"
          @click="returnGoodsMoney"
        >
          <span class="only">退货退款</span>
          <span>2022-08-09前可申请</span>
          <van-icon name="arrow" size="18" />
        </div>
      </div>
      <!-- 退货原因 -->
      <van-action-sheet
        :closeable="false"
        v-model="showBackReason"
        title="退款原因"
      >
        <div class="content">
          <div
            class="content-reason"
            @click="clickReason(item, index)"
            v-for="(item, index) in allReason"
            :key="index"
          >
            <van-icon
              :color="num == index ? '#FB2B53' : ''"
              name="checked"
              size="16"
            />
            <span>{{ item.reasonWord }}</span>
          </div>
        </div>
        <div class="commit-btn">
          <van-button
            type="primary"
            round
            color="#FB2B53 "
            block
            @click="commitReason"
            >提交</van-button
          >
        </div>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
//
import {
  getGoodsDetail2,
  createRefundItem,
  updateOrderrefunds
} from "@/api/afterSales";
export default {
  data() {
    return {
      show: false,
      //  仅退货
      showBackReason: false,
      allReason: [
        {
          reasonWord: "商家未按约定时间退款",
        },
        {
          reasonWord: "与商家商议一致退款",
        },
        {
          reasonWord: "快递信息长时间未更新",
        },
        {
          reasonWord: "不喜欢/不想要",
        },
        {
          reasonWord: "收货地址拍错",
        },
        {
          reasonWord: "其他",
        },
      ],
      chooseReason: null, //退货原因
      num: -1, //退货原因索引写活一个
      color: "", //颜色
      isReason: "", //请选择显示的是不是退货原因
      refundInfo: null,
      orderType: "",
    };
  },
  created() {
    console.log(this.$route, '路由')
    if (this.$route.query.session) {
      this.$store.commit("setSession", this.$route.query.session)
    }
    this.getGoodsDetail();
  },
  methods: {
    // 售后商品
    getGoodsDetail() {
      let id = this.$route.query.id
      getGoodsDetail2(id).then((res) => {
        if (res.data.code === 0) {
          this.refundInfo = res.data.data.listOrderItem;
          this.orderType = res.data.data.orderType;
          localStorage.setItem("refundInfo", JSON.stringify(this.refundInfo));
          localStorage.setItem("orderType", this.orderType);
        }
      });
    },

    //弹出仅退款原因
    backReason() {
      this.showBackReason = true;
    },
    //点击仅退款原因
    clickReason(item, index) {
      this.num = index;
      this.chooseReason = item.reasonWord;
    },
    // 提交退货原因
    commitReason() {
      if (this.$route.query.update == 'update') {
        this.updateApply()
      } else {
        this.createAppley()
      }
    }, 

    // 创建仅退款订单
    createAppley () {
      this.showBackReason = false;
      let params = {
        orderId: this.refundInfo[0].orderId,
        orderType: this.orderType,
        refundReson: this.chooseReason,
        status: '1'
      };
      createRefundItem(params).then((res) => {
        if (res.data.code === 0) {
          this.$Toast("创建退款成功");
          setTimeout(this.closePage,1000);
        }
      });
    },

    // 修改申请
    updateApply () {
      let data = {
        orderId:  this.refundInfo[0].orderId,
        id: this.$route.query.returnId,
        orderType: this.orderType,
        refundReson: this.chooseReason,
        status: '1',
        operateType: '2'
      }
      updateOrderrefunds(data).then(res => {
        if (res.data.code === 0) {
          this.$toast('仅退款修改成功')
          this.$router.replace({
            path: '/returnMoneyDetail',
            query: {
              id: this.$route.query.returnId,
              session: this.$route.query.session
            }
          })
        }
      })
    },

    //提交完成后退出页面
    closePage(){
      // 提交退货退款去的页面
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        // 退货退款方法
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        // 退货退款方法
        window.android.close();
      }
    },
    // 返回上一页
    onClickLeft() {
      if(this.$route.query.first == 0) {
         this.$router.back(-1)
      } else{
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isIOS) {
          window.webkit.messageHandlers.close.postMessage("up");
        }
        if (isAndroid) {
          window.android.close();
        }
      }
    },
    returnGoodsMoney() {
      this.$router.push({
        path: "/applyReturn",
        query: {
          id: this.$route.query.id,
          img: this.refundInfo[0].picUrl,
          first: 0,
          session: this.$route.query.session,
          update: this.$route.query.update == 'update' ? 'update' : null,
          returnId: this.$route.query.returnId
        },
      });
      localStorage.setItem("refundInfo", JSON.stringify(this.refundInfo));
      localStorage.setItem("orderType", this.orderType);
    },
  },
};
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  height: 100vh;
  background-color: #f1f1f1;
  margin-top: 60px;
}
/deep/ .van-nav-bar {
  position: fixed;
  height: 46px;
  width: 100%;
  top: 0;
}
.item {
  background-color: #fff;
  width: 100%;
  height: 158px;
  box-sizing: border-box;
  padding: 20px;
  ._item {
    .title {
      font-size: 17px;
      color: #333333;
    }
    .item-info {
      margin-top: 10px;
      display: flex;
      .desc-spec {
        margin-left: 10px;
        flex: 1;
        .desc {
          display: flex;
          justify-content: space-between;
        }
        .spec {
          margin-top: 10px;
          color: #999999;
          font-family: PingFang-SC-Regular;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
.choose-serve {
  font-size: 16px;
  padding: 15px;
  color: #999999;
}
.choose-item {
  background-color: #fff;
  .only-return {
    box-sizing: border-box;
    padding: 15px;
    border-bottom: 1px solid #f1f1f1;
    color: #999999;
    .only {
      flex: 1;
      color: #333333;
      font-family: PingFang-SC-Regular;
    }
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.content {
  margin: 0 15px 15px;
  .content-reason {
    padding: 13px 0;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    .van-icon {
      margin-right: 8px;
    }
  }
}
.commit-btn {
  margin: 15px 15px;
}
/deep/ .van-nav-bar__title {
  color: #000000;
  font-size: 17px;
  // font-weight: 600;
}
</style>